<template>
  <v-container fluid>
    <v-card class="pa-5 mt-3">
      <v-toolbar flat>
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('search')"
            outlined
            dense
            single-line
            hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="dialog = true"
        >
          {{ $t("add") }}
          <v-icon> mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>


      <v-data-table
          :headers="headers"
          :items-per-page2="10"
          :items="advertisements"
          :loading="loading"
          :footer-props="{showFirstLastPage: true,}"
          :server-items-length="total"
          :options.sync="options"
          @update:options="refreshTable"
      >
        <template v-slot:[`item.image`]="{ item }">
          <v-img class="rounded" :src="item.image" max-height="100" max-width="200"></v-img>
        </template>
        <template v-slot:[`item.content`]="{ item }">
          {{ strip_html(item.content) }}
        </template>
        <template v-slot:[`item.active`]="{ item }">
          <v-chip v-if="item.active" color="success" small label>
            {{ $t('advertisements.active') }}
          </v-chip>
          <v-chip v-else color="error" small label>
            {{ $t('advertisements.not_active') }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-row>
            <v-col cols="3">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      color="info"
                      @click="editItem(item)"
                      v-bind="attrs"
                      v-on="on"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span> {{ $t("advertisements.edit") }}</span>
              </v-tooltip>
            </v-col>
            <v-col cols="3">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      color="error"
                      @click="deleteItem(item)"
                      v-bind="attrs"
                      v-on="on"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span> {{ $t("advertisements.delete") }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-card>
    <Dialog
        :onClose="close"
        :dialog="dialog"
        :editedItem="editedItem"
        :editedIndex="editedIndex"
        :valid="valid"
        :resetValidation="resetValidation"
        @refreshTable="refreshTable"
    />
    <confirm-dialog
        :openDialog="dialogDelete"
        :onClicked="deleteItemConfirm"
        :onClose="closeDelete"
    />
  </v-container>
</template>
<script>
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import Dialog from "./Dialog.vue";
import {ObjectToQuery} from "@/plugins/helper";

export default {
  components: {ConfirmDialog, Dialog},
  data() {
    return {
      loading: true,
      valid: true,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      search: "",
      options: {},
      total: 0,
      advertisements: [],
      editedItem: {
        id: 0,
        title: null,
        start: null,
        end: null,
        image: null,
        imagePreview: null,
        content: "",
        active: false,
        comments: "",
        governorates: [],
        directorates: [],
        schools: [],
        types: [],
        classes: [],
      },
      defaultItem: {
        id: 0,
        title: null,
        start: null,
        end: null,
        image: null,
        imagePreview: null,
        content: "",
        active: false,
        comments: "",
        governorates: [],
        directorates: [],
        schools: [],
        types: [],
        classes: [],
      },
      resetValidation: 0,
    };
  },
  computed: {
    headers() {
      return [
        {text: this.$t("advertisements.title"), value: "title"},
        {text: this.$t("advertisements.start"), value: "start"},
        {text: this.$t("advertisements.end"), value: "end"},
        {text: this.$t("advertisements.image"), value: "image"},
        {text: this.$t("advertisements.content"), value: "content"},
        {text: this.$t("advertisements.status"), value: "active"},
        {text: "", value: "actions"},
      ];
    },
  },
  created() {
    if (!this.$store.getters.isInRole(101)) {
      this.$router.push({name: "notauthorize"});
    }
    this.loading = true;
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    search: {
      handler() {
        this.refreshTable();
      }
    },
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.advertisements.indexOf(item);
      this.editedItem = {
        ...item,
        image: null,
        imagePreview: item.image,
        active: !!item.active,
        schools: item.schools,
        governorates: item.governorates.map(item => item.id),
        directorates: item.directorates.map(item => item.id),
        types: item.types.map(item => item.id),
        classes: item.classes.map(item => item.class_id),
      };
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.advertisements.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      const deleteItem = this.advertisements[this.editedIndex];
      this.$axios
          .delete("advertisements/" + deleteItem.id)
          .then(() => {
            this.refreshTable();
          });

      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.resetValidation += 1;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    strip_html(string) {
      string = string.replace(/<(?:.|\n)*?>/gm, '');
      if (string.length > 50) {
        string = string.substr(0, 50) + '...';
      }
      return string;
    },
    refreshTable() {
      this.loading = true;
      let conditions = {
        ...this.options,
        search: this.search
      };
      const query = ObjectToQuery(conditions);
      this.$axios
          .get(`advertisements/?${query}`, {noToast: true, failureToast: true})
          .then((response) => {
            this.advertisements = response.data.data.data;
            this.total = response.data.data.total;
          })
          .finally(() => {
            this.loading = false;
          });
    },
  },
};
</script>
