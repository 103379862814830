var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"pa-5 mt-3"},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"outlined":"","dense":"","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.dialog = true}}},[_vm._v(" "+_vm._s(_vm.$t("add"))+" "),_c('v-icon',[_vm._v(" mdi-plus")])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items-per-page2":10,"items":_vm.advertisements,"loading":_vm.loading,"footer-props":{showFirstLastPage: true,},"server-items-length":_vm.total,"options":_vm.options},on:{"update:options":[function($event){_vm.options=$event},_vm.refreshTable]},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"rounded",attrs:{"src":item.image,"max-height":"100","max-width":"200"}})]}},{key:"item.content",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.strip_html(item.content))+" ")]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [(item.active)?_c('v-chip',{attrs:{"color":"success","small":"","label":""}},[_vm._v(" "+_vm._s(_vm.$t('advertisements.active'))+" ")]):_c('v-chip',{attrs:{"color":"error","small":"","label":""}},[_vm._v(" "+_vm._s(_vm.$t('advertisements.not_active'))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"info"},on:{"click":function($event){return _vm.editItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("advertisements.edit")))])])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("advertisements.delete")))])])],1)],1)]}}],null,true)})],1),_c('Dialog',{attrs:{"onClose":_vm.close,"dialog":_vm.dialog,"editedItem":_vm.editedItem,"editedIndex":_vm.editedIndex,"valid":_vm.valid,"resetValidation":_vm.resetValidation},on:{"refreshTable":_vm.refreshTable}}),_c('confirm-dialog',{attrs:{"openDialog":_vm.dialogDelete,"onClicked":_vm.deleteItemConfirm,"onClose":_vm.closeDelete}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }