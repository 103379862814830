<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <v-form ref="form" v-model="valid" >
      <v-card>
        <v-toolbar color="primary darken-1" dark>
          <v-card-title>
            <span class="">{{
              (editedIndex === -1 ? $t("add") : $t("edit")) +
              " " +
              $t("advertisements.index")
            }}</span>
          </v-card-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  outlined
                  hide-details
                  persistent-hint
                  v-model="editedItem.title"
                  :rules="[rulesWithLength.required]"
                  :label="$t('advertisements.title')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="editedItem.start"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        outlined
                        hide-details
                        persistent-hint
                        :rules="[rules.required]"
                        :label="$t('advertisements.start')"
                        v-model="editedItem.start"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="editedItem.start"
                      no-title
                      scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(editedItem.start)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6">
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :return-value.sync="editedItem.end"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        outlined
                        hide-details
                        persistent-hint
                        :rules="[rules.required]"
                        :label="$t('advertisements.end')"
                        v-model="editedItem.end"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="editedItem.end"
                      no-title
                      scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="menu2 = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.menu2.save(editedItem.end)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="12">
                <v-select
                    outlined
                    chips
                    multiple
                    :clearable="true"
                    :deletable-chips="true"
                    :items="governorates"
                    hide-details
                    v-model="editedItem.governorates"
                    @change="doSearch"
                    :label="$t('governorates')"
                    item-text="name"
                    item-value="id"
                ></v-select>
              </v-col>
              <v-col cols="12" md="12">
                <v-select
                    :disabled="editedItem.governorates.length === 0"
                    outlined
                    chips
                    multiple
                    :clearable="true"
                    :deletable-chips="true"
                    hide-details
                    :items="directorates_filtered"
                    v-model="editedItem.directorates"
                    @change="doSearch"
                    :label="$t('directorates.directorates')"
                    item-text="name"
                    item-value="id"
                ></v-select>
              </v-col>
              <v-col cols="12" md="12">
                <v-autocomplete
                    :disabled="editedItem.directorates.length === 0"
                    outlined
                    chips
                    hide-details
                    multiple
                    return-object
                    :clearable="true"
                    :deletable-chips="true"
                    :search-input.sync="search"
                    :items="schools"
                    :loading="isLoading"
                    item-text="name"
                    item-value="id"
                    v-model="editedItem.schools"
                    :label="$t('schools.schools')"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="12">
                <v-select
                    outlined
                    chips
                    multiple
                    :clearable="true"
                    :deletable-chips="true"
                    hide-details
                    :items="types"
                    item-text="school_type"
                    item-value="id"
                    v-model="editedItem.types"
                    :label="$t('schools.stage')"
                ></v-select>
              </v-col>
              <v-col cols="12" md="12">
                <v-select
                    :disabled="editedItem.types.length === 0"
                    outlined
                    chips
                    multiple
                    :clearable="true"
                    :deletable-chips="true"
                    hide-details
                    :items="classes_all"
                    item-text="name"
                    item-value="id"
                    v-model="editedItem.classes"
                    :label="$t('schools.classes')"
                ></v-select>
              </v-col>
              <v-col cols="12" md="12">
                <v-switch
                    outlined
                    hide-details
                    v-model="editedItem.active"
                    :label="$t('advertisements.active')"
                ></v-switch>
              </v-col>
              <v-col cols="12" md="12">
                <froala :tag="'textarea'" :config="config" v-model="editedItem.content"></froala>
              </v-col>
              <v-col cols="12" md="12" class="mb-0 pb-0">
                <v-file-input
                    outlined
                    hide-details
                    ref="refInputEl"
                    v-model="image"
                    type="file"
                    accept=".jpeg,.png,.jpg,GIF"
                    :label="$t('advertisements.image')"
                ></v-file-input>
                <v-img v-if="editedItem.imagePreview" :src="editedItem.imagePreview" max-height="300" class="px-3 my-3 rounded"/>
              </v-col>
              <v-col cols="12" md="12">
                <v-textarea
                    outlined
                    hide-details
                    type="comments"
                    v-model="editedItem.comments"
                    :label="$t('advertisements.comments')"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="12">
                <v-alert
                    v-if="editedItem.governorates.length === 0"
                    outlined
                    dense
                    type="warning"
                    prominent
                    border="left"
                >{{ $t('advertisements.all_governorates') }}</v-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            class="white--text"
            @click="save"
            :disabled="!valid || submitLoading"
            :loading="submitLoading"
            :min-width="100"
          >
            <v-icon>mdi-content-save-outline</v-icon>
            {{ $t("save") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="onClose">
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>

import {ObjectToQuery} from "@/plugins/helper";

export default {
  props: [
    "onSave",
    "onClose",
    "dialog",
    "editedItem",
    "editedIndex",
    "resetValidation",
  ],
  data() {
    return {
      valid: true,
      isLoading: false,
      search: "",
      loaded: false,
      menu: false,
      menu2: false,
      image:null,
      config: {
        key: "1C%kZV[IX)_SL}UJHAEFZMUJOYGYQE[\\ZJ]RAe(+%$==",
        language: 'ar',
        attribution: false,
        toolbarInline: false,
        toolbarButtons: [['undo', 'redo', 'paragraphFormatExtended'], ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']],
        paragraphFormatExtended: [
          {tag: 'h2', title: 'عنوان'},
          {title: 'اعتيادي'},
        ],
        useClasses: false,
        events: {
          'froalaEditor.initialized': function () {
            console.log('initialized')
          }
        }
      },
      submitLoading: false,
      directorates: [],
      classes: [],
      governorates: [],
      schools: [],
      types: [],
      rules: {
        required: (value) => !!value || this.$t("ThisFieldIsRequired"),
        image: (value) => !!value || this.$t("ThisFieldIsRequired"),
        length: (value) => value.length < 255 || this.$t("ThisFieldIsRequired"),
      },
      rulesWithLength: {
        required: (value) =>
          (value != null && value.length < 255) ||
          this.$t("ThisFieldIsRequired"),
      },
    };
  },
  watch: {
    resetValidation() {
      this.resetForm();
    },
    search: {
      handler() {
        this.doSearch();
      }
    },
    image:{
      handler() {
        this.editedItem.image = this.image
        this.fileChanged()
      }
    }
  },
  computed: {
    directorates_filtered() {
      return this.directorates
          .filter(directorate => this.editedItem.governorates.indexOf(directorate.governorate_id) !== -1);
    },
    classes_all() {
      const classes = [];
      this.classes.filter(c => this.editedItem.types.indexOf(c.id) !== -1).forEach(c => {
        c.stages.forEach(s => classes.push({id: s.id, name: c.name + ' - ' + s.name}))
      })
      return classes;
    },
  },
  async mounted() {
    this.$store
        .dispatch("GetUserDirectorates")
        .then((data) => {
          this.directorates = data;
        })
        .finally(() => {
          this.loading = false;
        });

    this.$store
        .dispatch("GetClasses")
        .then((data) => {
          this.classes = data;
        })
        .finally(() => {
          this.loading = false;
        });

    this.$store
        .dispatch("GetUserGovernorates")
        .then((data) => {
          this.governorates = data;
        })
        .finally(() => {
          this.loading = false;
        });

    this.$store
        .dispatch("GetSchoolsTypes")
        .then((data) => {
          this.types = data.map(type => ({
            ...type,
            school_type: this.$t('schoolTypes.' + type.school_type)
          }));
        })
        .finally(() => {
          this.loading = false;
        });

  },
  methods: {
    doSearch() {
      if (this.isLoading) return
      const conditions = {
        name: this.search ?? '',
      };
      conditions.directorate_id = this.editedItem.directorates
      conditions.governorate_id = this.editedItem.governorates
      this.isLoading = true
      const query = ObjectToQuery(conditions);
      this.$axios
          .get(`GetMySchools?${query}`, {noToast: true})
          .then((response) => {
            this.schools = [
              ...response.data.data,
              ...this.editedItem.schools,
            ];
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
    resetForm() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    save() {
      var val = this.$refs.form.validate();
      if (val) {
        this.submitLoading = true;
        const payload = new FormData();

        payload.append('title', this.editedItem.title);
        payload.append('start', new Date(this.editedItem.start).toISOString().slice(0, 19).replace('T', ' '));
        payload.append('end', new Date(this.editedItem.end).toISOString().slice(0, 19).replace('T', ' '));
        if (this.editedItem.image) {
          payload.append('image', this.editedItem.image);
        }
        payload.append('content', this.editedItem.content);
        payload.append('active', this.editedItem.active);
        payload.append('comments', this.editedItem.comments);
        this.editedItem.governorates.forEach(item => payload.append('governorates[]', item));
        this.editedItem.directorates.forEach(item => payload.append('directorates[]', item));
        this.editedItem.schools.forEach(item => payload.append('schools[]', item.id));
        this.editedItem.types.forEach(item => payload.append('types[]', item));
        this.editedItem.classes.forEach(item => payload.append('classes[]', item));
        if (this.editedIndex > -1) {
          this.$axios
            .post(
              "advertisements/" + this.editedItem.id,
                payload
            )
            .then((response) => {
              if (response.data.status === "Successful") {
                this.onClose();
                this.$emit("refreshTable");
              }
            })
              .finally(() => {
                this.submitLoading = false;
              })
        } else {
          this.$axios
            .post("advertisements", payload)
            .then((response) => {
              if (response.data.status === "Successful") {
                this.onClose();
                this.$emit("refreshTable");
              }
            })
              .finally(() => {
                this.submitLoading = false;
              })
        }
      }
    },
    fileChanged() {
      console.log(this.editedItem.image)
      if (!this.editedItem.image) {
        this.editedItem.imagePreview = null

        return null
      }

      const reader = new FileReader()
      reader.readAsDataURL(this.editedItem.image)
      reader.onload = () => {
        this.editedItem.imagePreview = reader.result
      }
      reader.onerror = error => {
        console.log('Error: ', error)
      }

      return null
    },
  },
};
</script>

